(() => {
    if (typeof window !== 'undefined') {
        (window as any).EPC = (window as any).EPC || {};
        (window as any).EPC.UI = Object.freeze({
            version: 'v1.2.19'
        });
        
        console.log(`Ferris Core: ${(window as any).EPC.UI.version as string}`); // eslint-disable-line no-console
    }
})();
