/* eslint-disable @typescript-eslint/ban-types */

/**
 * 
 * @param {String} domId 
 * @param {Object} sprite 
 */
export const appendIcons = (domId: string, sprite: object | string) => {
    if (document.getElementById(domId)) {
        return;
    }

    const div = document.createElement('div');
    div.setAttribute('id', domId);
    div.setAttribute('aria-hidden', 'true');
    div.setAttribute('style', 'display:none');
    div.setAttribute('data-version', '[[LIBRARY_VERSION_NUMBER]]');
    div.innerHTML = `${sprite as string}`;
    document.body.insertBefore(div, document.body.childNodes[0]);
};

/**
 * 
 * @param {String} domId 
 * @param {Object} sprite 
 */
export const injectSprite = (domId: string, sprite: string) => {
    if (document.body) {
        appendIcons(domId, sprite);
    } else {
        document.addEventListener('DOMContentLoaded', () => {
            appendIcons(domId, sprite);
        });
    }
};
